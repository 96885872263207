<template>
  <v-app-bar app>
    <v-toolbar-title>{{ title }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <user-log-out-menu/>

    <template v-slot:extension>
      <v-tabs>
        <v-tab :to="{ name: 'Logs-JobLogs' }">
          {{ $t("paths.jobLogs") }}
        </v-tab>
        <v-tab v-if=isCommercialVersion :to="{ name: 'Logs-SyncLogs' }">
          {{ $t("paths.syncLogs") }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import {Constants} from "../../constants/Constants";

export default {
  data() {
    return {
      isCommercialVersion: Constants.IS_COMMERCIAL_VERSION,
    }
  },
  components: {
    "user-log-out-menu": () => import("./../UserLogOutMenu.vue"),
  },
  props: ["title"],
};
</script>

<style scoped></style>
